/* reserve-appointment component */
.reserve-appointment {
  width: 100%;
  margin-bottom: 71px;
}

.reserve-appointment .section-title {
  color: #fff;
  font-family: Inter;
  font-size: var(--font-size-14px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
  margin-top: 28px;
  width: 100%;
}

.reserve-appointment .day-cards-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 4px;
  margin-top: 11px;
}

.reserve-appointment .time-slots button {
  padding: 16px;
  border-radius: 8px;
  background: var(--button-color);
  width: 100%;
  margin: 0 0 24px 0;
  color: #fff;
  font-family: var(--font-family);
  font-size: var(--font-size-16px);
  font-weight: 500;
  cursor: pointer;
}
