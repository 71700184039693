/* SalesmanResponseButtons.css file */

.button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem; /* Adjust the gap between buttons as needed */
  padding: 2rem;
  width: 100%;
}

.button {
  color: #eee;
  text-align: center;
  font-family: Inter;
  font-size: var(--font-size-12px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: rgba(255, 255, 255, 0.05);
  width: 90%;
  max-width: 400px;
  cursor: pointer;
}

.accept:hover {
  border: 1px solid #2da933;
  color: #2da933;
}

.decline:hover {
  border: 1px solid #ec312e;
  color: #ec312e;
}

.accept-with-code:hover {
  border: 1px solid #2da933;
  color: #2da933;
}
