.feedback-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(13, 13, 13, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--background-color);
  border-radius: 12px;
  padding: 30px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  font-family: "Inter", sans-serif;
  color: var(--primary-font-color);
  max-height: 70vh;
  overflow: auto;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: rgb(193, 0, 0);
  transition: color 0.3s;
}

.close-button:hover {
  color: red;
}

.rating {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
  text-align: center;
}

.rating svg {
  color: yellow;
  font-size: 21px;
  margin-right: 3px;
}
.feedback-list {
  margin-top: 20px;
}

.feedback-item {
  margin-bottom: 25px;
}

.question {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--primary-font-color);
}

.answer {
  font-size: 16px;
  line-height: 1.5;
  color: var(--highlight-color);
}
