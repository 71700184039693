.admin-page {
  color: var(--primary-font-color);
  font-family: "Inter", sans-serif; /* Using Inter font */
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.admin-page header {
  height: 86px;
  border-bottom: 1px solid #9a9a9a;
  color: var(--primary-font-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500; /* Medium weight */
}

.admin-page main {
  padding: 20px;
}

.admin-section {
  background-color: var(--background-color);
  padding: 16px;
  border-radius: 8px;
  max-width: 720px;
  margin: 0 auto;
}

.add-category-form .field-label,
.add-code-form .field-label,
.change-prompt-form .field-label {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: var(--font-size-16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 8px 0;
}

.add-category-form input,
.add-code-form input {
  display: flex;
  height: 48px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  border: none;
  margin-bottom: 10px;
  font-size: var(--font-size-16px);
}

.change-prompt-form textarea {
  display: flex;
  height: 100px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  border: none;
  margin-bottom: 10px;
  font-size: var(--font-size-16px);
  resize: vertical;
}

.add-category-form input:disabled,
.add-code-form input:disabled {
  color: black;
  background-color: rgb(192, 192, 192);
}

.add-category-form > input:last-of-type,
.add-code-form > input:last-of-type {
  margin-bottom: 10px;
}
.add-category-form button,
.add-code-form button,
.change-prompt-form button {
  padding: 16px;
  border-radius: 8px;
  background: var(--button-color);
  width: 100%;
  margin: 0 0 16px 0;
  color: #fff;
  font-family: var(--font-family);
  font-size: var(--font-size-16px);
  font-weight: 500;
  cursor: pointer;
  margin-top: 30px;
}

.category-select {
  display: flex;
  height: 48px;
  padding: 12px;
  width: 100%;
  border-radius: 8px;
  color: var(--primary-font-color);
  border: 1px solid white;
  outline: none;
  margin-bottom: 16px;
  font-size: var(--font-size-16px);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%2210%22 height%3D%225%22 viewBox%3D%220 0 10 5%22%3E%3Cpath fill%3D%22%23333%22 d%3D%22M0 0l5 5 5-5z%22/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 5px;
  cursor: pointer;
  font-weight: bold;
  background-color: var(--background-color);
}

.category-select option {
  padding: 10px;
}
