#register {
  display: flex;
  flex-wrap: nowrap;
}

.left,
.right {
  width: 50%;
  height: 100vh;
  position: relative;
}

.right {
  overflow-y: scroll;
}

.left img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.right .content {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 342px;
}
.right .logo {
  display: block;
  width: fit-content;
  margin: 40px auto 100px auto;
}

#register h2 {
  color: #fff;
  font-family: Inter;
  font-size: var(--font-size-32px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 auto 16px auto;
}
#register h2 > span {
  color: #1778f2;
}

#register .right .content > p:first-of-type {
  color: #fff;
  font-family: Inter;
  font-size: var(--font-size-20px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 40px 0;
}

.signup-form .field-label {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: var(--font-size-16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 8px 0;
}

.signup-form input {
  display: flex;
  height: 48px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  border: none;
  margin-bottom: 16px;
  font-size: var(--font-size-16px);
}

.signup-form > input:last-of-type {
  margin-bottom: 40px;
}
.signup-form button {
  padding: 16px;
  border-radius: 8px;
  background: var(--button-color);
  width: 100%;
  margin: 0 0 24px 0;
  color: #fff;
  font-family: var(--font-family);
  font-size: var(--font-size-16px);
  font-weight: 500;
  cursor: pointer;
}

#sign-up-with-google {
  width: 342px;
  height: 57.86px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid #dbd3d3;
  background-color: transparent;
  color: #fff;
  font-family: Poppins;
  font-size: var(--font-size-15px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.3px;
  margin-bottom: 187.2px;
}

#register-partnership {
  position: absolute;
  left: 32px;
  bottom: 36px;
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  align-items: center;
}

#register-partnership p {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: var(--font-size-16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#register-partnership .img-container {
  display: inline-block;
  height: 32px;
  overflow: hidden;
}

#register-partnership .img-container img {
  display: block;
  height: 100%;
  width: auto;
}

@media (max-width: 800px){

  #register .left {
    display: none;

  }
  #register .right {
    width: 100%;
  }
}