.settings-page {
  color: var(--primary-font-color);
  font-family: "Inter", sans-serif; /* Using Inter font */
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.settings-page header {
  height: 86px;
  border-bottom: 1px solid #9a9a9a;
  color: var(--primary-font-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500; /* Medium weight */
}

.settings-page main {
  padding: 20px;
}

.settings-section {
  background-color: var(--background-color);
  padding: 16px;
  border-radius: 8px;
  max-width: 720px;
  margin: 0 auto;
}

.questions-section h2 {
  margin-bottom: 20px;
  font-weight: bold; /* Semi-bold weight */
}

.questions-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style-type: none;
  padding: 0;
}

.question,
.question-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.question input,
.add-question-form input {
  flex-grow: 1;
  padding: 12px;
  border: 1px solid #555;
  border-radius: 4px;
  background-color: var(--secondary-background-color);
  color: var(--primary-font-color);
  font-size: 16px;
  outline: none;
  font-weight: 400; /* Regular weight */
}

.remove-question,
.add-question {
  border: none;
  border-radius: 4px;
  padding: 12px 20px;
  cursor: pointer;
  color: var(--primary-font-color);
  font-size: 16px;
  transition: background-color 0.3s ease;
  font-weight: 500; /* Medium weight */
}

.remove-question {
  background-color: #ff3131;
}

.remove-question:hover {
  background-color: #c00909;
}

.add-question {
  background-color: var(--button-color);
  margin-top: 20px;
  margin-left: auto;
}

.add-question:hover {
  background-color: #005cb7;
}

.add-question-form {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.add-question-form input {
  border: 1px solid #555;
  border-radius: 4px;
  padding: 12px;
  flex-grow: 1;
  background-color: white;
  color: black;
  font-size: 16px;
  outline: none;
  font-weight: 400; /* Regular weight */
}

.add-question-form button {
  background-color: var(--button-color);
  border: none;
  border-radius: 4px;
  padding: 12px 20px;
  cursor: pointer;
  color: var(--primary-font-color);
  font-size: 16px;
  transition: background-color 0.3s ease;
  font-weight: 500; /* Medium weight */
}

.add-question-form button:hover {
  background-color: #0058b0;
}

.update-data-form .field-label {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: var(--font-size-16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 8px 0;
}

.update-data-form input,
.buy-code-form input {
  display: flex;
  height: 48px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  border: none;
  margin-bottom: 16px;
  font-size: var(--font-size-16px);
}

.update-data-form input:disabled {
  color: black;
  background-color: rgb(192, 192, 192);
}

.update-data-form > input:last-of-type {
  margin-bottom: 40px;
}
.update-data-form button,
.buy-code-form button {
  padding: 16px;
  border-radius: 8px;
  background: var(--button-color);
  width: 100%;
  margin: 0 0 24px 0;
  color: #fff;
  font-family: var(--font-family);
  font-size: var(--font-size-16px);
  font-weight: 500;
  cursor: pointer;
  margin-top: 30px;
}

.category-select {
  display: flex;
  height: 48px;
  padding: 12px;
  width: 100%;
  border-radius: 8px;
  color: var(--primary-font-color);
  border: 1px solid white;
  outline: none;
  margin-bottom: 16px;
  font-size: var(--font-size-16px);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%2210%22 height%3D%225%22 viewBox%3D%220 0 10 5%22%3E%3Cpath fill%3D%22%23333%22 d%3D%22M0 0l5 5 5-5z%22/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 5px;
  cursor: pointer;
  font-weight: bold;
  background-color: var(--background-color);
}
