@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins&display=swap");

body {
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
}

/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(76, 76, 76);
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

/* Hide scrollbar arrows */
::-webkit-scrollbar-button {
  display: none;
}

/* For Firefox */
@-moz-document url-prefix() {
  * {
    scrollbar-color: rgb(76, 76, 76) rgba(0, 0, 0, 0);
    scrollbar-width: thin;
  }
}

/* For Internet Explorer and Edge */
* {
  scrollbar-face-color: rgb(76, 76, 76);
  scrollbar-track-color: rgba(0, 0, 0, 0);
  -ms-overflow-style: none; /* Hide scrollbar in Edge */
}

button {
  border: none;
}
