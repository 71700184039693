.cta-container.mt {
  margin-top: 83px;
}

.cta-container {
  max-width: 342px;
}

.cta input[type="text"] {
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: #fff;
  margin-bottom: 16px;
  box-sizing: border-box;
  font-family: var(font-family);
  font-size: var(--font-size-16px);
  font-weight: 500;
}

.cta button {
  padding: 16px;
  border-radius: 8px;
  background: var(--button-color);
  width: 100%;
  margin: 0 0 51px 0;
  color: #fff;
  font-family: var(--font-family);
  font-size: var(--font-size-16px);
  font-weight: 500;
  cursor: pointer;
}
