.dashboard {
  color: white;
  font-family: Inter;
  min-height: 100vh;
  position: relative;
}
.dashboard .loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dashboard-main {
  margin-left: 17%;
}
header {
  height: 86px;
  border-bottom: 1px solid #9a9a9a;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-text {
  margin-left: 30px;
  text-align: left;
}
.header-text p:first-of-type {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  margin: 10px;
}

.header-text p:last-of-type {
  font-size: 14px;
  font-weight: 400;
  margin: 10px;
  line-height: 20px;
}

.notifications-button {
  width: 40px;
  height: 40px;
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
}
.notifications-button svg {
  font-size: 20px;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto;
  grid-gap: 16px;
  background-color: #0d0d0d;
  padding: 16px;
}

.card {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: #222;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 160px;
  font-size: 16px;
  text-align: center;
}

.card svg {
  font-size: 30px;
  margin-bottom: 10px;
}
.card span {
  font-size: 24px;
  font-weight: 700;
}
@media (max-width: 1100px) {
  .cards-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .chart-container {
    grid-column: 1 / 3;
    grid-row: 2 /3;
  }
  .card {
    height: 100px;
  }

  .cards-container svg {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .card span {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  .card br {
    display: none;
  }
}
.chart-container {
  grid-column: 1 / 4;
  grid-row: 2 /4;
  background-color: #222;
  padding: 20px;
  border-radius: 10px;
  font-family: "Times New Roman", Times, serif;
  color: black;
}

.recharts-responsive-container {
  display: flex;
  align-items: center;
  overflow: visible;
}

.meetings-history {
  padding: 16px;
}

.filter-bar {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 16px;
  border-radius: 8px;
}

.table-container {
  max-height: 600px;
  overflow-y: scroll;
  margin-top: 16px;
  border-radius: 8px;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: #222;
  border-radius: 8px;
}
th,
td {
  text-align: left;
  padding: 12px;
}
td svg {
  font-size: inherit;
  margin: 0 4px 0 0;
  color: yellow;
}
th {
  border-radius: 8px;
  background-color: #2a2a2a;
}
tr:nth-child(even) {
  background-color: #2a2a2a;
}
.status-indicator {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.pending {
  background-color: #ffcc00;
}
.cancelled {
  background-color: #ff3333;
}
.completed {
  background-color: #33cc33;
}

.status-data span {
  display: block;
}
.status-data {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.yellow {
  color: yellow;
}

aside {
  width: 17%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

aside hr {
  border-top: 1px solid #33cc33;
}

.in-page-links {
  margin-top: 86px;
}
.links {
  margin: 10% auto;
  width: 100%;
}

aside a {
  text-decoration: none;
}

aside .link {
  text-decoration: none;
  color: #05c766;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20%;
  gap: 7px;
  height: 42px;
  cursor: pointer;
}

.user-info {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #05c766;
  padding-left: 20%;
  margin-top: 10%;
}

.user-info .email,
td .email {
  font-size: 12px;
  margin-left: 0;
}

@media (max-width: 1100px) {
  .dashboard-main {
    margin-left: 20%;
  }
  .dashboard aside {
    width: 20%;
  }
  .dashboard aside .link,
  .user-info {
    padding-left: 8%;
  }
}

.in-page-links .link.active {
  color: white;
  background-color: #3b7156;
  border-right: 3px solid #05c766;
}
aside svg {
  font-size: 16px;
  margin: 0;
}

aside span {
  display: block;
  margin-left: 5px;
}

#dashboard-nav {
  display: none;
}
@media (max-width: 900px) {
  .dashboard table {
    font-size: 14px;
  }
  .dashboard aside {
    display: none;
  }
  .dashboard-main {
    margin-left: 0;
  }
  #dashboard-nav {
    display: block;
  }
  .notifications-button {
    margin-right:  70px;
  }
}

