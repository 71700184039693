:root {
  --background-color: #0d0d0d;
  --secondary-background-color: #0566c705;
  --button-color: #0374e8;
  --highlight-color: #05c766;
  --primary-font-color: #fff;
  --font-size-12px: 12px;
  --font-size-14px: 14px;
  --font-size-15px: 15px;
  --font-size-16px: 16px;
  --font-size-18px: 18px;
  --font-size-20px: 20px;
  --font-size-24px: 24px;
  --font-size-32px: 32px;
  --font-size-36px: 36px;
  --font-family: Inter, sans-serif;
}

@media (max-width: 720px) {
  :root {
    --font-size-12px: 12px;
    --font-size-14px: 14px;
    --font-size-15px: 15px;
    --font-size-16px: 16px;
    --font-size-18px: 18px;
    --font-size-20px: 20px;
    --font-size-24px: 24px;
    --font-size-32px: 32px;
    --font-size-36px: 36px;
  }
}
