.menu-wrapper {
  position: absolute;
  top: 20px;
  right: -180px;
  height: 300vh;
  transition: right 0.5s ease; /* Simplify transition property */
  width: 180px;
  z-index: 1000; /* Ensure menu is above other content */
}

.menu-wrapper.open {
  right: 0;
}

.menu-bar {
  width: 25px;
  height: 4px;
  background-color: var(--primary-font-color);
  margin: 4px 0;
  transition: background-color 0.4s;
}

.menu-icon {
  position: absolute;
  left: -50px; /* Adjust positioning */
  cursor: pointer;
  width: 35px; /* Increase clickable area */
}

.menu {
  background-color: var(--background-color);
  padding: 10px;
  border-radius: 5px;
  color: #05c766;
  font-family: Inter;
  font-weight: 600;
  font-size: 16px; /* Simplify font size */
  text-align: left; /* Align menu items to the left */
  height: 300vh;
}

.menu a {
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 8px 16px; /* Add padding for better spacing */
  transition: background-color 0.3s; /* Smooth background color transition */
}

.menu a:hover {
  background-color: rgba(0, 200, 0, 0.1); /* Add hover effect */
}

.logout-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 5px 0; /* Adjust vertical margin */
  padding: 8px 16px; /* Add padding for better spacing */
  cursor: pointer;
  color: rgb(255, 49, 49);
  transition: background-color 0.3s; /* Smooth background color transition */
  border-radius: 3px; /* Add border radius for rounded corners */
}

.logout-wrapper:hover {
  background-color: rgba(255, 49, 49, 0.1); /* Add hover effect */
}

.menu svg {
  font-size: 20px;
  margin-right: 8px; /* Add spacing between icon and text */
  margin-bottom: 0px;
}

@media (max-width: 720px) {
  .menu-wrapper {
    right: -180px;
  }
  .menu-wrapper .menu {
    height: 300vh;
  }
}
