.react-tel-input .form-control {
  width: 100%;
  height: 48px;
  padding: 16px;
  margin-bottom: 16px;
  padding-left: 52px;
  font-size: var(--font-size-16px);
  border-radius: 8px;
}
.react-tel-input {
  margin-bottom: 16px;
}

.react-tel-input .flag-dropdown {
  border-radius: 8px 0 0 8px;
  color: black;
}
