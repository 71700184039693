.feedback-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--background-color);
}

.feedback-page {
  max-width: 720px;
  width: 100%;
  padding: 40px;
  background-color: var(--background-color);
  color: var(--primary-font-color);
  font-family: "Inter", sans-serif;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.feedback-header {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 30px;
}

.feedback-page .logo {
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 30px;
  display: block;
}

.feedback-rating {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  font-size: 32px; /* Increase the font size to make stars larger */
}

.rating-label {
  margin-left: 20px;
  font-size: 20px; /* Adjusting label font size */
}

.feedback-question {
  margin-bottom: 15px;
}

.feedback-question:last-of-type {
  margin-bottom: 30px;
}

.question-text {
  font-size: 20px;
  font-weight: 500;
  display: block;
  margin-bottom: 11px;
}

.feedback-answer {
  width: 100%;
  padding: 15px;
  border: 1px solid #555;
  border-radius: 8px;
  /* background-color: var(--secondary-background-color); */
  /* color: var(--primary-font-color); */
  font-family: "Inter", sans-serif;
  font-size: 16px;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
}

.submit-button {
  margin: 0 auto;
  /* max-width: 500px; */
  width: 100%;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #0074e8;
  border: none;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: var(--font-size-16px);
  font-weight: 500;
  border: none;
}

/* Overriding MUI Rating component styles */
.MuiRating-root {
  font-size: 35px !important;
}

.MuiRating-iconEmpty {
  color: #ccc !important;
}

.MuiRating-iconFilled {
  color: yellow;
}
