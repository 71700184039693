#landing-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--background-color);
}

#landing-left,
#landing-right {
  width: 50%;
}

.part {
  height: auto;
  overflow: hidden;
  position: relative;
}

.part img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.part .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

#part-1 {
  width: 100%;
  height: 711px;
}

#part-1 .content {
  width: 47.5%;
  max-width: 342px;
  margin: 13.8% auto;
}

#part-2 .content,
#part-3 .content,
#part-4 .content,
#part-5 .content {
  width: 62.5%;
  margin-left: 26.5%;
}

#part-3 .content li,
#part-4 .content li,
#part-5 .content li {
  color: #fff;
  font-family: Poppins;
  font-size: var(--font-size-16px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

#landing-container .content .logo {
  margin-bottom: 52px;
}

#landing-container h2 {
  color: #fff;
  font-family: var(--font-family);
  font-size: var(--font-size-32px);
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 125% */
}

#landing-container p {
  color: #fff;
  font-family: var(--font-family);
  font-size: var(--font-size-16px);
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 40px;
}

.cta-sign-in {
  color: #e6e6e6;
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
  text-align: center;
}

.cta-sign-in span {
  color: var(--button-color);
  cursor: pointer;
}

hr {
  border: 0;
  height: 1px;
  background-color: #343a40;
  width: 89%;
}

#part-2 .content > h2:first-of-type {
  font-weight: 600;
}

#landing-container .content > h2:first-of-type {
  margin: 96px 0 0 0;
}
.subhead {
  color: #8d8d8d !important;
  font-family: Inter;
  font-size: var(--font-size-16px);
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
  margin: 0;
}

#landing-container ol {
  list-style-type: decimal;
  color: white;
  padding-left: 15px;
}
#landing-container li {
  color: #e6e6e6;
  font-family: var(--font-family);
  font-size: var(--font-size-18px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.36px;
}

#landing-container ol p {
  color: #999;
  font-family: Inter;
  font-size: var(--font-size-16px);
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
  margin-bottom: 28px;
  margin-top: 4px;
}

#part-5 h2:first-of-type {
  margin: 0;
}

#landing-last-logo {
  margin: 56px;
}

.social-media {
  display: flex;
  justify-content: space-between;
  margin: 76px 0;
}
.social-icon {
  color: white;
  font-size: var(--font-size-36px);
  display: block;
  cursor: pointer;
}

#rights {
  color: #8d8d8d;
  font-family: Poppins;
  font-size: var(--font-size-16px);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.32px;
  text-align: center;
}

#text-content-1,
#text-content-2,
#text-content-3,
#text-content-4 {
  width: 82.5%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

#text-content-1 {
  bottom: 23%;
  text-align: right;
  align-items: flex-end;
}

#text-content-2 {
  bottom: 10%;
  text-align: right;
  align-items: flex-end;
}

#text-content-3 {
  bottom: 19%;
  text-align: center;
  align-items: center;
}
#text-content-4 {
  bottom: 13%;
  align-items: left;
}
#text-content-1 .main-text,
#text-content-2 .main-text,
#text-content-3 .main-text,
#text-content-4 .main-text {
  color: #fff;
  font-family: Poppins;
  font-size: var(--font-size-32px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

#text-content-1 .sub-text,
#text-content-2 .sub-text,
#text-content-3 .sub-text,
#text-content-4 .sub-text {
  color: #fff;
  font-family: Poppins;
  font-size: var(--font-size-24px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

#text-content-1 .sub-text {
  width: 70%;
}

#text-content-2 .sub-text {
  width: 70%;
}

#text-content-3 .sub-text {
  width: 60%;
}

#text-content-4 .sub-text {
  width: 100%;
}

@media (max-width: 720px) {
  #landing-left {
    display: none !important;
  }
  #landing-right {
    width: 100%;
  }
  #landing-right .content {
    width: 80%;
    max-width: 720px;
    margin: 0 auto;
  }
  #landing-right .cta-container {
    margin: 0 auto;
  }
  #landing-right .logo {
    display: block;
    margin: 40px auto 52px auto;
  }

  #landing-container .content > h2:first-of-type {
    margin: 52px 0 0 0;
  }
}
