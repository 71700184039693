/* time slot component */

.time-slot {
  height: 47px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.time-slot p {
  color: #eee;
  text-align: center;
  font-family: Inter;
  font-size: var(--font-size-12px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.time-slot.active {
  border: 1px solid var(--green, #05c766);
}

.time-slot.active p {
  color: #05c766;
}
.reserved {
  text-decoration: line-through;
  color: gray !important;
}
