.video-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  background-color: #1778f2;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.video-container {
  transition: 0.5s;
  width: 90%;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
.video-container video {
  max-height: 70vh;
}
