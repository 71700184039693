#home {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  overflow: hidden;
}

.left,
.right {
  width: 50%;
  height: 100vh;
  position: relative;
}
.left {
  background-color: rgba(255, 255, 255, 0.05);
  position: relative;
}
.right {
  overflow-y: scroll;
}

.upload-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 144px;
  overflow: hidden;
}

.upload-image-icon-container {
  width: 64px;
  margin: 0 auto 24px;
}

.upload-image button {
  width: 100%;
  height: 40px;
  padding: 16px;
  border-radius: 8px;
  background: #0566c7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: Inter;
  font-size: var(--font-size-14px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
}

.right .content {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 342px;
}
.right .logo {
  display: block;
  width: fit-content;
  margin: 70px auto 48px auto !important;
}

#home h2 {
  color: #fff;
  font-family: Inter;
  font-size: var(--font-size-32px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 auto 16px auto;
}
#home h2 > span {
  color: #1778f2;
}

#home .right .content > p:first-of-type {
  color: #fff;
  font-family: Inter;
  font-size: var(--font-size-20px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 40px 0;
}

.user-media {
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.5);
  position: relative;
}

#background-image-holder {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#presentation-text {
  display: block;
  min-width: 66%;
  max-width: 70%;
  min-height: 20%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: fit-content;
  text-align: center;
  background-color: transparent;
  outline: none;
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: var(--font-size-24px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  resize: none;
}

#presentation-text::placeholder {
  color: white;
}

#change-image {
  position: absolute;
  right: 32px;
  top: 32px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: var(--font-size-14px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  padding: 12px 16px;
}

.change-image-loading {
  position: absolute;
  right: 77px;
  top: 52px;
}

@media (max-width: 720px) {
  .left,
  .right {
    width: 100% !important;
  }
  #home {
    display: flex;
    flex-direction: column;

    flex-wrap: nowrap;
  }
  .right {
    height: fit-content;
  }

  #presentation-text {
    top: 40%;
  }
}
