.verify-email {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.verify-email .content {
  max-width: 720px;
  margin: 10vh auto 0 auto;
  height: fit-content;
}

.verify-email h1 {
  color: #fff;
  font-family: Inter;
  font-size: var(--font-size-32px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;
}
.verify-email p {
  color: #fff;
  font-family: Inter;
  font-size: var(--font-size-20px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 40px;
  margin-top: 16px;
}
.verify-email .field-label {
  color: #fff;
  font-family: Inter;
  font-size: var(--font-size-16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
}

.verify-email input {
  display: flex;
  padding: var(--font-size-16px);
  align-items: center;
  height: 48px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  background: #fff;
  margin-bottom: 40px;
}

.verify-email button {
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #0074e8;
  color: #fff;
  font-family: Inter;
  font-size: var(--font-size-16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  cursor: pointer;
  margin-bottom: 16px;
  position: relative;
}

.resend-button span {
  display: block;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  color: white;
}

.resend-button[disabled] {
    background-color: gray;
    cursor: default;

}
