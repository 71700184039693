/* cards styles */
.days .day-card {
  height: 38px;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  opacity: 0.3;
  display: flex;
  padding: 16px 10px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  cursor: pointer;
}
.days .day-card.active {
  background-color: rgba(255, 255, 255, 0.05);
  opacity: 1;
}

.day-card p {
  margin: 0;
  padding: 0;
}
.day-card .day-name {
  color: #999;
  text-align: center;
  font-family: Inter;
  font-size: var(--font-size-12px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.day-card .slots-number {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: var(--font-size-12px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 11px;
}

.day-card .slots-number.active {
  color: #05c766;
}
