.alert-message {
  position: fixed;
  bottom: 20px;
  width: 70%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .alert-message {
    width: 90%;
  }
}
