.reservation-form .field-label {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: var(--font-size-16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 8px 0;
}

.reservation-form input {
  display: flex;
  height: 48px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  border: none;
  margin-bottom: 16px;
  font-size: var(--font-size-16px);
}

.reservation-form textarea {
  display: block;
  width: 100%;
  font-family: "Poppins";
  font-size: var(--font-size-16px);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 40px;
}

.reservation-form button {
  padding: 16px;
  border-radius: 8px;
  background: var(--button-color);
  width: 100%;
  margin: 0 0 24px 0;
  color: #fff;
  font-family: var(--font-family);
  font-size: var(--font-size-16px);
  font-weight: 500;
  cursor: pointer;
}

/* time slots */

.time-slots {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 11px;
}
.reservation-form .time-slots button {
  width: 100%;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #0074e8;
  border: none;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: var(--font-size-16px);
  font-weight: 500;
  border: none;
}
