#reserve {
  display: flex;
  flex-wrap: nowrap;
}

.left,
.right {
  width: 50%;
  height: 100vh;
  position: relative;
}
.left {
  background-color: rgba(255, 255, 255, 0.05);
  position: relative;
}
.right {
  overflow-y: scroll;
}

.upload-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 144px;
  overflow: hidden;
}

.upload-image-icon-container {
  width: 64px;
  margin: 0 auto 24px;
}

.upload-image button {
  width: 100%;
  height: 40px;
  padding: 16px;
  border-radius: 8px;
  background: #0566c7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: Inter;
  font-size: var(--font-size-14px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
}

.right .content {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 342px;
}
.right .logo {
  display: block;
  width: fit-content;
  margin: 40px auto 48px auto;
}

#reserve h2 {
  color: #fff;
  font-family: Inter;
  font-size: var(--font-size-32px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 16px 0;
}
#reserve h2 > span {
  color: #1778f2;
}

#reserve .right .content > p:first-of-type {
  color: #fff;
  font-family: Inter;
  font-size: var(--font-size-20px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 40px 0;
}

.center-content {
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.center-content .image-container {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  display: block;
}

.center-content p:first-of-type {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: var(--font-size-20px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#reserved-partnership {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
}

#reserved-partnership p {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: var(--font-size-16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#reserved-partnership .img-container {
  display: inline-block;
  height: 32px;
  overflow: hidden;
}

#reserved-partnership .img-container img {
  display: block;
  height: 100%;
  width: auto;
}

.down-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50px;
}

.down-container p {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: var(--font-size-16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.down-container a {
  color: white;
}

.overlay-div {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: relative;
}

#presentation-text-paragraph {
  display: block;
  min-width: 66%;
  max-width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: fit-content;
  text-align: center;
  background-color: transparent;
  outline: none;
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: var(--font-size-24px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  word-wrap: break-word;
}

.background-video {
  position: relative;
  width: 100%;
  height: 100vh; /* Adjust as needed */
  overflow: hidden;
}

.background-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the entire div */
}

@media (max-width: 720px) {
  #reserve {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .left,
  .right {
    width: 100% !important;
  }
  .right {
    height: fit-content;
  }

  .center-content {
    width: 90%;
  }
}
