.wave-player {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
}
.wave-player.home {
  bottom: 20%;
}
.wave-player.reserve {
  bottom: 10%;
}
.play-pause {
  color: #ededed;
  cursor: pointer;
  display: block;
  margin: 15px auto 0 auto;
}
