.success-content {
  max-width: 400px;
  font-family: Inter, sans-serif;
  margin: auto;
  padding: 10px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-direction: column;
  flex-wrap: nowrap;
}

.success-content p {
  font-size: 30px;
  font-weight: bold;
}
.success-content button {
  padding: 16px;
  border-radius: 8px;
  background: var(--button-color);
  width: 100%;
  margin: 0 0 24px 0;
  color: #fff;
  font-family: var(--font-family);
  font-size: var(--font-size-16px);
  font-weight: 500;
  cursor: pointer;
  margin-top: 30px;
}
