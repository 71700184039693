/* select-dates component */
.select-dates {
  width: 100%;
  margin-bottom: 71px;
}

.select-dates .section-title {
  color: #fff;
  font-family: Inter;
  font-size: var(--font-size-14px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
  margin-top: 28px;
  width: 100%;
}

.select-dates .day-cards-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 4px;
  margin-top: 11px;
}

/* time slots */

.time-slots {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 11px;
}
.time-slots button {
  width: 100%;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #0074e8;
  border: none;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: var(--font-size-16px);
  font-weight: 500;
}
.share-buttons {
  margin-top: 11px;
  display: flex;
  flex-wrap: nowrap;
  gap: 4px;
}
.share-buttons > button {
  color: #eee;
  text-align: center;
  font-family: Inter;
  font-size: var(--font-size-12px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: rgba(255, 255, 255, 0.05);
}
