#upload-actions {
  position: absolute;
  bottom: 44px;
  left: 50%;
  transform: translateX(-50%);
  width: 358px;
  max-width: 90%;
}

#upload-actions p {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: var(--font-size-14px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 11px;
}

#upload-buttons-container {
  display: flex;
  flex-wrap: no-wrap;
  gap: 4px;
}

#upload-buttons-container button {
  width: 50%;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
  color: #eee;
  text-align: center;
  font-family: Inter;
  font-size: var(--font-size-12px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.upload-loading-container {
  width: 50%;
}
